<template>
  <v-app>
    <v-main>
      <v-container fluid class="px-15">
        <v-row>
          <v-col cols="12">
            <v-card elevation="0">
              <v-card-title class="bold-title">Historial de pagos</v-card-title>
              <v-card-text>Obtén un desglose de todos tus pagos</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="px-15">
        <div class="display-flex align-items-center justify-start">
          <h2 class="text-title mon-bold">
            {{ texts.status.paid }}
          </h2>
        </div>

        <v-container fluid>
          <v-simple-table style="width: 100%; border: 1px solid #e8e8e8">
            <template class="full-width">
              <thead style="justify-content: center; background-color: #f2f2f4">
                <tr>
                  <th class="text-center">Payment Date</th>
                  <th class="text-center">
                    {{ texts.dataTable.purchaseDate }}
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.provider }}
                  </th>
                  <th class="text-center">{{ texts.dataTable.invoice }}</th>
                  <th class="text-center">
                    {{ texts.dataTable.totalAmount }} MXN
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.exchangeRateProtection }}
                  </th>
                  <th class="text-center">Exchange Rate Used</th>

                  <th class="text-center">
                    {{ texts.dataTable.currencyProfit }}
                  </th>
                  <!-- <th class="text-center">
                    {{ texts.dataTable.totalProtectedUsd }}
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.totalUsdToday }}
                  </th> -->
                  <th class="text-center">
                    {{ texts.dataTable.currencyGain }} USD
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.status }}
                  </th>
                  <th>Comprobante</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="exchangeRateData in exchangeRateDataPaid"
                  :key="exchangeRateData.id"
                >
                  <td class="text-center">
                    {{
                      formatDate(
                        exchangeRateData.paymentExchangeRate[0].dPayment_date
                      )
                    }}
                  </td>
                  <td class="text-center">
                    {{ formatDate(exchangeRateData.dPurchaseDate) }}
                  </td>
                  <td class="text-center">
                    {{ exchangeRateData.sProviderName }}
                  </td>
                  <td class="text-center">
                    {{ exchangeRateData.sInvoiceNumber }}
                  </td>
                  <td class="text-center">
                    ${{ formatMoney(exchangeRateData.dTotalAmount) }}
                  </td>
                  <td class="text-center">
                    {{ exchangeRateData.dInvoiceExchangeRateProtection }}
                    MXN
                  </td>
                  <td class="text-center">
                    {{ exchangeRateData.paymentExchangeRate[0].dRate }}
                    MXN
                  </td>
                  <td
                    class="text-center"
                    :style="{
                      color:
                        exchangeRateData.dCurrencyProfitPercentage < 0
                          ? 'red'
                          : 'green'
                    }"
                    style="font-weight: 800"
                  >
                    {{ exchangeRateData.dCurrencyProfitPercentage }} %
                  </td>
                  <!-- <td class="text-center">
                    {{ formatMoney(exchangeRateData.dTotalProtectedUsd) }}
                    USD
                  </td>
                  <td class="text-center">
                    {{ formatMoney(exchangeRateData.dTotalUsdToday) }} USD
                  </td> -->
                  <td
                    class="text-center"
                    :style="{
                      color:
                        exchangeRateData.dCurrencyGain < 0 ? 'red' : 'green'
                    }"
                    style="font-weight: 800"
                  >
                    {{ formatMoney(exchangeRateData.dCurrencyGain) }} USD
                  </td>
                  <td class="text-center">
                    {{
                      texts.status[exchangeRateData.statusName] ||
                      exchangeRateData.statusName
                    }}
                  </td>
                  <td>
                    <v-btn
                      color="#F5F7F9"
                      elevation="0"
                      :disabled="
                        exchangeRateData.paymentExchangeRate[0]
                          .sPayment_reference === null
                      "
                      @click="openReceipt(exchangeRateData)"
                    >
                      <v-icon class="mdi mdi-eye-outline"></v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: () => ""
    },
    exchangeRateDataPaid: {
      type: Array,
      default: () => []
    },
    formatDate: {
      type: Function,
      default: () => ""
    },
    formatMoney: {
      type: Function,
      default: () => ""
    },
    openReceipt: {
      type: Function,
      default: () => ""
    }
  },
  name: "HistorialDePagos"
};
</script>

<style>
.text-center {
  text-align: center;
  font-family: montserrat-regular;
}

.bold-title {
  color: #151d27;
  font-size: 24px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 36px;
  word-wrap: break-word;
}
</style>
